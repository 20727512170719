<template>
  <div id="misc">
    <img
      class="misc-mask"
      height="226"
      :src="require(`@/assets/images/misc/misc-mask-${$vuetify.theme.dark ? 'dark' : 'light'}.png`)"
    />

    <div
      class="membership-pricing col-sm-12  col-md-8 offset-md-2 v-card v-sheet v-sheet--outlined theme--dark px-5 py-4 mx-auto text-center"
    >
      <h2 class="text-2xl font-weight-semibold text--primary d-flex align-center justify-center">
        <span class="me-2 mb-6">Contact Us</span>
      </h2>

      <iframe
        title="Contact Form"
        src="https://plugins.crisp.chat/urn:crisp.im:contact-form:0/contact/c85e7dd2-20bf-4477-bb18-6fee9a829fbb"
        referrerpolicy="origin"
        sandbox="allow-forms allow-popups allow-scripts"
        width="100%"
        height="600px"
        frameborder="0"
      ></iframe>
    </div>
  </div>
</template>
<script>

export default {
  data() {
    return {
      email: this.$store.state.user.email,
    }
  },
}
</script>

<style lang="scss">
@import '~@/plugins/vuetify/default-preset/preset/misc.scss';

.contactForm input {
  border: 2px solid #fff;
  border-radius: 20px;
  line-height: 31px;
  color: #fff;
  margin: 4px;
  padding: 5px 10px;
}

.contactForm textarea {
  overflow: auto;
  resize: vertical;
  border: 2px solid #fff !important;
  border-radius: 20px;
  /* line-height: 31px; */
  color: #fff;
  margin: 4px;
  padding: 5px 10px;
  height: 150px;
}
</style>
